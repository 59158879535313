<template>
    <div class="custom-scroll">
        <div class="d-flex flex-nowrap justify-content-center mb-3">
            <b-input-group class="px-0 mr-2 mb-xl-0 w-50">
                <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                    type="search"
                    v-model="search"
                    @keydown.enter="updateSearch"
                    :placeholder="$t('associationSearchPlaceholder')"
                    id="searchInput"
                ></b-form-input>
            </b-input-group>
            <b-btn variant="primary" class="search-button" @click="updateSearch">
                {{ $t('search') }}
            </b-btn>
        </div>
        <div>
            <b-row class="align-items-stretch">
                <b-col :md="4" :sm="6" class="d-flex flex-column associated-section">
                    <h5 class="text-primary font-weight-bold">
                        {{ $t('customerAccounts') }} <b-icon-building />
                    </h5>
                    <InfiniteScroll
                        class="list-wrapper flex-grow"
                        ref="customersList"
                        id="customers-list"
                        @scroll.stop
                        :get-next="fetchCustomers"
                        :per_page="50"
                        :total_pages="Number(totalCustomerPages.totalPages)"
                    >
                        <b-list-group-item
                            @scroll.stop
                            :active="activeCustomerId === customer.customerId"
                            @click="selectActiveCustomer(customer.customerId)"
                            :class="{
                                'selected-row ':
                                    isCustomerSelected(customer.customerId) &&
                                    !(activeCustomerId === customer.customerId),
                            }"
                            button
                            v-for="customer in filteredCustomers"
                            :key="customer.customerId"
                        >
                            <div>
                                <span class="font-weight-bolder"> {{ customer.name }}, </span>
                                <span class="text-muted">
                                    {{ customer.country ? `${customer.country},` : '' }}
                                    {{ customer.city ? `${customer.city},` : '' }}
                                    {{ customer.ocosCustomerNumber }}
                                </span>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item v-if="!filteredCustomers.length">
                            <span class="text-muted text-center">
                                {{ $t('customersListPlaceholder') }}
                            </span>
                        </b-list-group-item>
                    </InfiniteScroll>
                    <b-btn
                        class="mt-2 align-items-center d-flex justify-content-center"
                        variant="primary"
                        :disabled="!activeCustomerId || isCustomerSelected(activeCustomerId)"
                        @click="addSelectedCustomer"
                    >
                        {{ $t('selectCustomer') }}

                        {{
                            isCustomerSelected(activeCustomerId)
                                ? `(${$t('customerSelected')})`
                                : ''
                        }}
                        <b-icon class="ml-2" icon="plus-circle" />
                    </b-btn>
                </b-col>
                <b-col :md="4" :sm="6" class="d-flex flex-column associated-section">
                    <div class="d-flex">
                        <h5
                            class="font-italic tab-button font-weight-bold text-nowrap"
                            :class="{'tab-button__active': !showCustomersSurgeons}"
                            @click="
                                showCustomersSurgeons = false;
                                activeSurgeonsIds = [];
                            "
                        >
                            {{ $t('surgeonsColumnTitle') }}
                            <b-icon-person />
                        </h5>
                        <template v-if="activeCustomerId">
                            <h5 class="text-primary font-italic mx-2">|</h5>
                            <h5
                                class="font-italic font-weight-bold pointer tab-button"
                                :class="{'tab-button__active': showCustomersSurgeons}"
                                @click="
                                    showCustomersSurgeons = true;
                                    activeSurgeonsIds = [];
                                "
                            >
                                {{
                                    customersSurgeons.length > 1
                                        ? $t('customersSurgeons')
                                        : $t('customersSurgeon')
                                }}
                            </h5>
                        </template>
                    </div>

                    <InfiniteScroll
                        v-show="!showCustomersSurgeons"
                        class="list-wrapper flex-grow"
                        ref="surgeonsList"
                        id="surgeons-list"
                        @scroll.stop
                        :get-next="fetchSurgeons"
                        :per_page="50"
                        :total_pages="Number(totalSurgeonPages.totalPages)"
                    >
                        <b-list-group-item
                            :active="
                                isSurgeonActive(surgeon.doctorId) &&
                                !isSurgeonSelected(surgeon.doctorId)
                            "
                            @click="surgeonClicked(surgeon.doctorId)"
                            :class="{'selected-row': isSurgeonSelected(surgeon.doctorId)}"
                            :disabled="isSurgeonSelected(surgeon.doctorId)"
                            button
                            v-for="surgeon in filteredSurgeons"
                            :key="surgeon.doctorId"
                        >
                            <div>
                                <span class="font-italic font-weight-bolder">
                                    {{ surgeon.fullName }},
                                </span>
                                <span class="text-muted">
                                    {{ surgeon.country ? `${surgeon.country},` : '' }}
                                    {{ surgeon.city ? `${surgeon.city},` : '' }}
                                    {{ surgeon.contactPartyNumber }}
                                </span>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item v-if="!filteredSurgeons.length && !isSurgeonsLoading">
                            <span class="text-muted text-center">
                                {{ $t('surgeonsListPlaceholder') }}
                            </span>
                        </b-list-group-item>

                        <div
                            v-if="isSurgeonsLoading"
                            class="d-flex w-100 h-100 justify-content-center align-items-center"
                        >
                            <b-spinner class="text-primary" />
                        </div>
                    </InfiniteScroll>

                    <InfiniteScroll
                        v-show="showCustomersSurgeons"
                        class="list-wrapper flex-grow"
                        ref="customerSurgeonsList"
                        id="customer-surgeons-list"
                        @scroll.stop
                        :get-next="fetchSurgeons"
                        :per_page="50"
                        :total_pages="Number(surgeonsByCustomerTotalPages)"
                    >
                        <b-list-group-item
                            :active="
                                isSurgeonActive(surgeon.doctorId) &&
                                !isSurgeonSelected(surgeon.doctorId)
                            "
                            @click="surgeonClicked(surgeon.doctorId)"
                            :class="{'selected-row': isSurgeonSelected(surgeon.doctorId)}"
                            :variant="isSurgeonSelected(surgeon.doctorId) ? 'primary' : ''"
                            :disabled="isSurgeonSelected(surgeon.doctorId)"
                            button
                            v-for="surgeon in filteredSurgeons"
                            :key="surgeon.doctorId"
                        >
                            <div>
                                <span class="font-italic font-weight-bolder">
                                    {{ surgeon.fullName }},
                                </span>
                                <span class="text-muted">
                                    {{ surgeon.country ? `${surgeon.country},` : '' }}
                                    {{ surgeon.city ? `${surgeon.city},` : '' }}
                                    {{ surgeon.contactPartyNumber }}
                                </span>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item v-if="!filteredSurgeons.length && !isSurgeonsLoading">
                            <span class="text-muted text-center">
                                {{ $t('surgeonsListPlaceholder') }}
                            </span>
                        </b-list-group-item>

                        <div
                            v-if="isSurgeonsLoading"
                            class="d-flex w-100 h-100 justify-content-center align-items-center"
                        >
                            <b-spinner class="text-primary" />
                        </div>
                    </InfiniteScroll>

                    <b-btn
                        class="mt-2 align-items-center d-flex justify-content-center"
                        variant="primary"
                        :disabled="!activeSurgeonsIds.length"
                        @click="addSelectedSurgeons"
                    >
                        {{
                            activeSurgeonsIds.length > 1
                                ? $t('selectSurgeons', {count: activeSurgeonsIds.length})
                                : $t('addSurgeon')
                        }}
                        <b-icon class="ml-2" icon="plus-circle" />
                    </b-btn>
                </b-col>
                <b-col :md="4" :sm="12" class="d-flex flex-column associated-section">
                    <div class="d-flex justify-content-between align-items-center mb-1 flex-wrap">
                        <div class="d-flex align-items-center">
                            <h5 class="m-0 text-primary font-weight-bold text-capitalize">
                                {{ $t('listOfSelection') }}
                            </h5>
                            <div
                                v-if="selectedFilter === 'all' || selectedFilter === 'customers'"
                                class="icon-button ml-2"
                                v-show="expandableLength"
                                @click.stop="isAllExpanded ? collapseAll() : expandAll()"
                                v-b-tooltip:hover="
                                    isAllExpanded ? $t('collapseAll') : $t('expandAll')
                                "
                            >
                                <b-icon-chevron-bar-contract v-if="isAllExpanded" />
                                <b-icon-chevron-bar-expand v-else />
                            </div>
                        </div>

                        <b-button-group>
                            <b-btn
                                :variant="selectedFilter === 'all' ? 'primary' : 'secondary'"
                                @click="selectedFilter = 'all'"
                            >
                                {{ $t('all') }}
                            </b-btn>
                            <b-btn
                                :variant="selectedFilter === 'customers' ? 'primary' : 'secondary'"
                                @click="selectedFilter = 'customers'"
                            >
                                {{ $t('customers') }}
                            </b-btn>
                            <b-btn
                                :variant="selectedFilter === 'surgeons' ? 'primary' : 'secondary'"
                                @click="selectedFilter = 'surgeons'"
                            >
                                {{ $t('surgeons') }}
                            </b-btn>
                        </b-button-group>
                    </div>

                    <b-list-group class="list-wrapper flex-grow py-1">
                        <b-list-group-item
                            class="py-2"
                            v-for="entity in filteredSelectedEntities"
                            :key="entity.doctorId || entity.customerId"
                            :class="{
                                'expandable-customer': isExpandable(entity.customerId),
                            }"
                            @click="expandOrCollapseCustomer(entity.customerId)"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <template v-if="entity.customerId">
                                    <div class="d-flex" style="max-width: 85%">
                                        <div>
                                            <template v-if="isExpandable(entity.customerId)">
                                                <b-icon-chevron-up
                                                    class="mr-2"
                                                    v-if="isExpanded(entity.customerId)"
                                                />
                                                <b-icon-chevron-down v-else class="mr-2" />
                                            </template>
                                            <b-icon-building class="mr-2" />
                                        </div>
                                        <div class="text-truncate">
                                            <div class="font-weight-bolder white-space-nowrap">
                                                {{ entity.name }},
                                            </div>
                                            <div
                                                class="text-muted white-space-nowrap text-truncate"
                                            >
                                                {{ entity.country ? `${entity.country},` : '' }}
                                                {{ entity.city ? `${entity.city},` : '' }}
                                                {{
                                                    entity.ocosCustomerNumber ||
                                                    entity.accountNumber
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="entity.doctorId">
                                    <div class="font-italic">
                                        <span>
                                            <b-icon-person />
                                        </span>
                                        <span> {{ entity.fullName }}, </span>
                                        <span class="text-muted">
                                            {{ entity.country ? `${entity.country},` : '' }}
                                            {{ entity.city ? `${entity.city},` : '' }}
                                            {{ entity.contactPartyNumber }}
                                        </span>
                                    </div>
                                </template>
                                <div class="d-flex align-items-center">
                                    <b-btn
                                        @click.stop="removeSelectedEntity(entity)"
                                        variant="none"
                                        size="sm"
                                    >
                                        <b-icon
                                            :id="`remove-icon-${
                                                entity.doctorId || entity.customerId
                                            }`"
                                            class="pointer h5 m-0 icon-button"
                                            icon="trash"
                                        />
                                    </b-btn>

                                    <b-tooltip
                                        :target="`remove-icon-${
                                            entity.doctorId || entity.customerId
                                        }`"
                                        :title="$t('removeTooltip')"
                                    />
                                </div>
                            </div>
                            <b-collapse
                                v-if="isExpandable(entity.customerId)"
                                :visible="entity.customerId && isExpanded(entity.customerId)"
                            >
                                <b-list-group class="pl-4">
                                    <b-list-group-item
                                        class="font-italic pl-4 bg-transparent"
                                        v-for="surgeon in getSurgeonsOfCustomer(entity.customerId)"
                                        :key="entity.customerId + surgeon.doctorId"
                                    >
                                        <span>
                                            <b-icon-person />
                                        </span>
                                        <span> {{ surgeon.fullName }}, </span>
                                        <span class="text-muted">
                                            {{ surgeon.country ? `${entity.country},` : '' }}
                                            {{ surgeon.city ? `${entity.city},` : '' }}
                                            {{
                                                surgeon.contactPartyNumber ||
                                                surgeon.ocosDoctorId ||
                                                surgeon.ocosSurgeonId ||
                                                surgeon.doctorId
                                            }}
                                        </span>
                                    </b-list-group-item>
                                </b-list-group>
                            </b-collapse>
                        </b-list-group-item>

                        <b-list-group-item v-if="!filteredSelectedEntities.length">
                            <div
                                class="d-flex justify-content-between align-items-center text-muted"
                            >
                                {{
                                    selectedFilter === 'all'
                                        ? $t('selectedAllPlaceholder')
                                        : selectedFilter === 'customers'
                                        ? $t('selectedCustomersPlaceholder')
                                        : selectedFilter === 'surgeons'
                                        ? $t('selectedSurgeonsPlaceholder')
                                        : ''
                                }}
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                    <b-btn
                        class="mt-2 w-50 align-self-end"
                        variant="primary"
                        @click="submitForm"
                        :disabled="isLoading"
                    >
                        <span v-if="!isLoading"> {{ $t('save') }} </span>
                        <template v-else>
                            <b-spinner small />
                        </template>
                    </b-btn>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import InfiniteScroll from '@/components/InfiniteScroll.vue';

export default {
    components: {InfiniteScroll},
    props: {
        isPlain: {
            default: true,
        },
    },
    emits: ['submit'],
    data: () => ({
        search: '',
        currentSearch: '',
        activeCustomerId: '',
        activeSurgeonsIds: [],
        customersSurgeons: [],
        selectedFilter: 'all',
        showCustomersSurgeons: false,
        selectedEntities: [],
        isLoading: false,
        isSurgeonsLoading: false,
        isCustomersLoading: false,
        surgeonsByCustomerTotalPages: '0',
        customersMap: [],
        expandedCustomers: new Set(),
        expandableLength: 0,
    }),
    computed: {
        ...mapGetters({
            surgeons: 'doctors/list',
            customers: 'customers/list',
            totalSurgeonPages: 'doctors/details',
            totalCustomerPages: 'customers/details',
        }),
        isAllExpanded() {
            return this.expandableLength === this.expandedCustomers.size;
        },
        getSurgeonsOfCustomer() {
            return (customerId) =>
                this.customersMap.find((customer) => customer.id === customerId)?.surgeons || [];
        },
        isExpandable() {
            return (customerId) => this.getSurgeonsOfCustomer(customerId)?.length;
        },
        isExpanded() {
            return (customerId) => this.expandedCustomers.has(customerId);
        },
        filteredSurgeons() {
            return this.activeCustomerId && this.showCustomersSurgeons
                ? this.customersSurgeons
                : this.surgeons;
        },
        filteredCustomers() {
            return this.customers;
        },
        isSurgeonActive() {
            return (id) => !!this.activeSurgeonsIds.find((surgeonId) => surgeonId === id);
        },
        isSurgeonSelected() {
            return (id) => !!this.selectedEntities.find((surgeon) => surgeon.doctorId === id);
        },
        isCustomerSelected() {
            return (id) => !!this.selectedEntities.find((customer) => customer.customerId === id);
        },
        selectedCustomers() {
            return this.selectedEntities.filter((entity) => !!entity.customerId);
        },
        selectedSurgeons() {
            return this.selectedEntities.filter((entity) => !!entity.doctorId);
        },
        filteredSelectedEntities() {
            return this.selectedFilter === 'all'
                ? this.selectedEntities
                : this.selectedFilter === 'customers'
                ? this.selectedCustomers
                : this.selectedSurgeons;
        },
    },
    methods: {
        ...mapMutations({
            setSpinner: 'app/setSpinner',
        }),
        ...mapActions({
            getCustomers: 'customers/fetchList',
            getSurgeons: 'doctors/fetchList',
        }),
        removeSelectedEntity(entity) {
            if (entity.doctorId) {
                this.selectedEntities = this.selectedEntities.filter(
                    (surgeon) => surgeon.doctorId !== entity.doctorId
                );
            }
            if (entity.customerId) {
                this.selectedEntities = this.selectedEntities.filter(
                    (customer) => customer.customerId !== entity.customerId
                );
                this.removeCustomer(entity.customerId);
            }
        },
        async getSurgeonsByCustomer(query, customerId) {
            const params = new URLSearchParams(query).toString();
            return await this.$http.get('customers/' + customerId + '/doctors?' + params, {
                query,
            });
        },
        selectActiveCustomer(customerId) {
            if (customerId !== this.activeCustomerId) {
                this.activeSurgeonsIds = [];
            }
            this.showCustomersSurgeons = true;
            this.$refs.customerSurgeonsList.resetState();
            this.activeCustomerId = customerId;
            this.fetchSurgeons();
        },
        async fetchCustomers(offset = 0) {
            const query = {
                search: this.currentSearch,
                per_page: 50,
                sort_by: 'name',
                sort_order: 'asc',
                offset,
            };
            this.isCustomersLoading = true;
            await this.getCustomers(query, true);
            this.isCustomersLoading = false;
        },
        async fetchSurgeons(offset = 0) {
            const query = {
                search: this.currentSearch,
                per_page: 50,
                customerFilter: false,
                sort_by: 'fullName',
                sort_order: 'asc',
                offset,
            };
            if (this.activeCustomerId && this.showCustomersSurgeons) {
                this.isSurgeonsLoading = true;
                this.customersSurgeons = [];
                const res = await this.getSurgeonsByCustomer(query, this.activeCustomerId);
                this.customersSurgeons = query.offset
                    ? [...this.customersSurgeons, ...res.data.results]
                    : res.data.results;
                this.surgeonsByCustomerTotalPages = res.headers['x-orion-totalpages'];
                this.isSurgeonsLoading = false;
            } else {
                await this.getSurgeons(query, true);
            }
        },
        resetState() {
            this.activeCustomerId = '';
            this.activeSurgeonsIds = [];
            this.customersSurgeons = [];
            this.$refs.surgeonsList.resetState();
            this.$refs.surgeonsList.resetState();
            this.showCustomersSurgeons = false;
        },
        async updateSearch() {
            this.setSpinner(true);
            this.currentSearch = this.search;
            this.resetState();
            await Promise.allSettled([this.fetchSurgeons(), this.fetchCustomers()]);
            this.setSpinner(false);
        },
        surgeonClicked(id) {
            if (this.isSurgeonActive(id)) {
                this.removeSurgeon(id);
            } else {
                this.activeSurgeonsIds.push(id);
            }
        },
        removeSurgeon(id) {
            const index = this.activeSurgeonsIds.indexOf(id);
            this.activeSurgeonsIds.splice(index, 1);
        },
        addSelectedSurgeons() {
            this.selectedEntities.push(
                ...this.filteredSurgeons.filter(
                    (surgeon) =>
                        this.isSurgeonActive(surgeon.doctorId) &&
                        !this.isSurgeonSelected(surgeon.doctorId)
                )
            );
            this.activeSurgeonsIds = [];
        },
        addSelectedCustomer() {
            this.selectedEntities.push(
                this.customers.find((customer) => customer.customerId === this.activeCustomerId)
            );
            this.populateCustomerSurgeons(this.activeCustomerId);
        },
        async submitForm() {
            if (this.isPlain) {
                this.isLoading = true;
                try {
                    const res = await this.$http.post(
                        `users/${this.$route.params.userId}/connected-entities`,
                        {
                            userId: this.$route.params.userId,
                            connectedCustomerIds: this.selectedCustomers.map(
                                (customer) => customer.customerId
                            ),
                            connectedSurgeonIds: this.selectedSurgeons.map(
                                (surgeon) => surgeon.doctorId
                            ),
                        }
                    );
                    if (res.data.success) {
                        setTimeout(
                            () =>
                                this.$bvToast.toast(this.$t('userUpdatedText'), {
                                    title: this.$t('userUpdatedTitle'),
                                    variant: 'success',
                                    solid: true,
                                }),
                            0
                        );
                    }
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.$emit('submit', {
                    ConnectedSurgeonIds: this.selectedSurgeons.map((surgeon) => surgeon.doctorId),
                    ConnectedCustomerIds: this.selectedCustomers.map(
                        (customer) => customer.customerId
                    ),
                });
            }
        },
        async populateCustomerSurgeons(customerId) {
            const res = await this.getSurgeonsByCustomer(
                {
                    per_page: 1000,
                },
                customerId
            );
            this.customersMap = [
                ...this.customersMap,
                {id: customerId, surgeons: res.data.results},
            ];

            if (res.data.results?.length) {
                this.expandableLength += 1;
            }
        },
        removeCustomer(customerId) {
            const index = this.customersMap.findIndex((customer) => customer.id === customerId);
            if (this.customersMap[index].surgeons?.length) {
                this.collapseCustomer(customerId);
                this.expandableLength -= 1;
            }
            if (index > -1) {
                this.customersMap.splice(index, 1);
            }
        },
        expandOrCollapseCustomer(customerId) {
            if (this.isExpandable(customerId)) {
                if (this.isExpanded(customerId)) {
                    this.collapseCustomer(customerId);
                } else {
                    this.expandedCustomers.add(customerId);
                }
            }
            this.expandedCustomers = new Set([...this.expandedCustomers.values()]);
        },
        collapseCustomer(customerId) {
            this.expandedCustomers.delete(customerId);
        },
        expandAll() {
            this.selectedEntities.forEach((entity) => {
                if (entity.customerId) {
                    if (this.isExpandable(entity.customerId)) {
                        this.expandedCustomers.add(entity.customerId);
                    }
                }
            });
            this.expandedCustomers = new Set([...this.expandedCustomers.values()]);
        },
        collapseAll() {
            this.expandedCustomers.clear();
            this.expandedCustomers = new Set();
        },
    },
    async beforeMount() {
        await this.fetchCustomers();
        await this.fetchSurgeons();

        if (this.isPlain) {
            const [surgeons, customers] = await Promise.allSettled([
                this.$http.get(`users/${this.$route.params.userId}/connected-surgeons`),
                this.$http.get(`users/${this.$route.params.userId}/connected-customers`),
            ]);

            customers.value?.data?.results.forEach((customer) => {
                this.populateCustomerSurgeons(customer.customerId);
            });

            this.selectedEntities.push(
                ...customers.value?.data?.results,
                ...surgeons.value?.data?.results
            );
        }
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/variables';

.search-button {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}

.list-wrapper {
    border: 1px solid $gray-light;
    overflow-y: auto;
    height: 30rem;
}

::v-deep .card-body {
    padding: 0;
}

::v-deep .list-group-item {
    border: none;
    border-radius: unset;
}

.selected-row {
    color: black;
    background-color: $gray-light;

    &:hover {
        background-color: $gray;
    }
}

.tab-button {
    cursor: pointer;
    color: $gray-light;

    &:hover {
        color: $primary-light;
    }

    &__active {
        cursor: default;
        color: $primary;

        &:hover {
            color: $primary;
        }
    }
}

.icon-button {
    color: $gray-dark;

    &:hover {
        &:disabled {
            color: $gray-dark;
        }
        color: $primary;
    }
}

.associated-section {
    max-height: 35rem;
    min-height: 25rem;
    padding: 1rem;
}

.expandable-customer {
    cursor: pointer;

    -webkit-transition: background-color 100ms linear;
    -moz-transition: background-color 100ms linear;
    -o-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;

    &:hover {
        background-color: $primary-lighter;
    }
    &:active {
        background-color: $primary;
    }
}
</style>
